@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('./_assets.scss');

$banner_header:clamp(1.5rem,3vw,3rem);
$highlights_header:clamp(1.5rem,3vw,2rem);
$brand_blue:#00516d;
$brand_green:#009c35;
$footer_color:#ededed;
$banner_color:#f9fcf1;
$blue_hover:#026789;
$paragraph-color:rgb(59, 59, 59);
$dash-fonts:clamp(.7rem,.9vw,1rem);


body{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: 'Roboto', sans-serif;
    overflow-y: scroll;
    top: 0 !important;

    .dash_body{
        position: relative;
        .overlay{
            position: absolute;
            width: 100%;
            height:-webkit-fill-available;
            background-color: rgba(0, 0, 0, 0.39);
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;


            .loader {
                color: #fff;
                font-size: 10px;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                position: relative;
                text-indent: -9999em;
                animation: mulShdSpin 1.3s infinite linear;
                transform: translateZ(0);
              }
              
              @keyframes mulShdSpin {
                0%,
                100% {
                  box-shadow: 0 -3em 0 0.2em, 
                  2em -2em 0 0em, 3em 0 0 -1em, 
                  2em 2em 0 -1em, 0 3em 0 -1em, 
                  -2em 2em 0 -1em, -3em 0 0 -1em, 
                  -2em -2em 0 0;
                }
                12.5% {
                  box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
                  3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
                  -2em 2em 0 -1em, -3em 0 0 -1em, 
                  -2em -2em 0 -1em;
                }
                25% {
                  box-shadow: 0 -3em 0 -0.5em, 
                  2em -2em 0 0, 3em 0 0 0.2em, 
                  2em 2em 0 0, 0 3em 0 -1em, 
                  -2em 2em 0 -1em, -3em 0 0 -1em, 
                  -2em -2em 0 -1em;
                }
                37.5% {
                  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                   3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
                   -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
                }
                50% {
                  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                   3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
                   -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
                }
                62.5% {
                  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                   3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
                   -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
                }
                75% {
                  box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
                  3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
                  -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
                }
                87.5% {
                  box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
                  3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
                  -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
                }
              }
                
        }
    }
    
    
    a{
        text-decoration: none;
    }

    .about_image{
        width: 100%;
        margin: 15px 0;
    }

    #google_translate_element {
        display: none;
      }
      .skiptranslate {
        display: none;
      }

      
 #goog-gt-tt, .goog-te-balloon-frame,div#goog-gt-{display: none !important;}

 .goog-text-highlight { background: none !important; box-shadow: none !important;}

      

    section{

        

        .mobile_menu{
            width: 100%;
           overflow: hidden;
            background-color: white;
            position: absolute;
            top: -10%;
            z-index: 2;
            transition: .6s;
            opacity: 0;
            box-shadow: 0px 2px 5px rgb(232, 232, 232);
            //display: none;
            visibility: hidden;

            .content{
                display: flex;
                width: 200%;
                transition: .5s;
                ul{
                    
                    width: 100%;
                    padding: 20px;
                    box-sizing: border-box;
                    list-style-type:none;
                   
                    .close_li{
                        text-align: right;
                        padding: 20px;
                    }
                   
                    a{
                        text-decoration: none;
                        color: $brand_blue;

                        li{
                            list-style-type: none;
                            padding: 20px 15px;
                            font-size: 1.2rem;
                            font-weight: bold;
                            color: $brand_blue;
                            border-bottom: 1px solid rgb(217, 217, 217);
    
                          
                            
                          
                        .material-symbols-outlined {
                        font-variation-settings:
                        'FILL' 0,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 48
                        }
    
                        }
                    }
                  
    
                   
    
                    .union_li{
                        display: flex;
                        justify-content: space-between;
                    }

                    .last_li{
                        border: none;
                    }
                   
                }

               
    
                .sub_menu{
                    width: 100%;
                    ul{
                        li:nth-child(1){
                            text-align: left;
                        }

                        li{
                            padding: 20px 15px;
                            font-size: 1.2rem;
                            font-weight: bold;
                            color: $brand_blue;
                            border-bottom: 1px solid rgb(217, 217, 217);
                            text-transform: capitalize;
                        }
                    }
                }
            }
            
        }

        header{
            padding-top: 20px;
            
            background-color: $banner_color;
            //border-bottom: 1px solid $brand_blue;
        }
        .container{
            width: 70%;
            margin: 0 auto;
            //----Navigation-----//
            nav{
                border-bottom: 1px solid rgba(211, 211, 211, 0.515);
                padding: 10px 0;
                font-weight: 400;
                .flexbox{
                    display: flex;
                    justify-content: space-between;
                    .flex:nth-child(1){
                        width: 20%;
                    }

                    .flex:nth-child(2){
                        width: 80%;
                        justify-content: flex-end;
                    }
                    .flex{
                        display: flex;
                        align-items: center;
                        .logo{
                            width: min(150px,40%);
                            img{
                                width: 100%;
                                mix-blend-mode: multiply;
                            }
                        }

                        ul{
                            display: flex;
                            align-items: center;
                            li{
                                list-style-type: none;
                                padding-left: 40px;
                                font-size: .8rem;
                                //font-weight: bold;
                                cursor: pointer;

                                a{
                                    text-decoration: none;
                                    color: black;

                                    &:hover{
                                        color: $brand_green;
                                    }
                                }

                                &:hover{
                                    color: $brand_green;
                                    
                                }

                                
                            }

                            li:nth-child(3){
                                padding-left: 20px;
                            }

                            .union_resources{
                                position: relative;
                                height: 30px;
                                display: flex;
                                align-items: center;

                                .ur_text{
                                    display: flex;
                                    align-items: center;

                                    .left_arrow{
                                        width: 0;
                                        height: 0;
                                        border-left: 25px solid transparent;
                                        border-right: 25px solid transparent;
                                        border-top: 50px solid #555;
                                        transform: scale(.2);
                                        margin-left: -5px;
                                    }
                                }

                                .dropdown{
                                    //display: none;
                                    position: absolute;
                                    top: 35px;
                                    visibility: hidden;
                                    opacity: 0;
                                    transition: .3s;
                                    z-index: 10;
                                    ul{
                                        padding-left: 0;
                                       border: 1px solid gray;
                                       background-color: white;
                                        display: flex;
                                        flex-direction: column;
                                       color: black;
                                        padding: 15px 30px;
                                        

                                        li{
                                            padding: 0;
                                            text-transform: capitalize;
                                            width: 150px;
                                            padding: 15px 15px;

                                            &:hover{
                                                color: $brand_blue;
                                            }
                                        }

                                        li:nth-child(2){
                                            border-top: solid 1px rgb(204, 204, 204);
                                        }
                                    }
                                   
                                }

                                &:hover div:nth-child(1){
                                    color: $brand_green;
                                }

                                &:hover .dropdown{
                                    visibility: visible;
                                    opacity: 1;
                                    top: 30px;
                                    
                                }
                                  
                            }
                        }

                       

                        ul:nth-child(2){
                            display: flex;
                            li{
                                
                                margin: 0;
                                color: $brand_blue;
                                padding: 10px;
                                border: 2px solid $brand_blue;
                                cursor: pointer;
                            }

                            /*li:nth-child(1){
                                background-color: $brand_blue;
                                color: white;
                            }*/

                        }

                        .material-symbols-outlined {
                            display: none;
                            font-size: 2.5rem;
                            font-variation-settings:
                            'FILL' 0,
                            'wght' 400,
                            'GRAD' 0,
                            'opsz' 48
                            }
                    }

                }
            }

            //------Banner-----//
            .banner{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 30px auto;
                width: 95%;

                .flex{
                  padding-bottom: 20px;
                    
                  
                    .content{
                        width: max(80%);
                        h2{
                            margin: 0;
                            color: $brand_blue;
                        }
                        h1{
                            margin: 0;
                            font-size: $banner_header;
                            line-height: 1.2;
                            color: $brand_blue;
                        }

                        p{
                            line-height: 1.8;
                            margin: 15px 0px;
                            //color: gray;
                            font-size: .9rem;
                            font-weight: 400;
                        }

                        button{
                            border: none;
                            color: white;
                            background-color: $brand_green;
                            padding: 15px 35px;
                            font-weight: bold;
                            cursor: pointer;
                            border-radius: 5px;
                            margin-top: 10px;

                            &:hover{
                                background-color: #00b63d;
                            }
                        }

                       
                }

                .img{
                    width: 400px;
                    height: 400px;
                    background-image: url(../images/about_image.png);
                    background-position: center;
                    border-radius: 50%;
                    transform: rotate(-10deg);
                }
            }
            }

            //------Main Section-----//
            .main_section{

                .meeting{
                    text-align: center;
                    margin: 40px 0;
                    padding: 50px;
                    border: 1px dotted $brand_green;
                    border-radius: 10px;

                    h1{
                        font-size: clamp(1.3rem,3vw,2rem);
                        line-height: 1.5;
                        margin: 30px;
                    }

                    hr{
                        width: 10%;
                    }

                    button{
                        background-color: $brand_blue;
                        padding: 20px 75px;
                        border: none;
                        border-radius: 10px;
                        color: white;
                        font-size: clamp(1rem,2vw,1.3rem);
                        font-weight: bold;
                        margin-top: 10px;
                        cursor: pointer;

                        &:hover{
                            background-color: $blue_hover;
                        }
                    }
                    p{
                        &:nth-child(2){
                            width: 50%;
                            margin: 0 auto;
                            color: gray;
                            line-height: 1.8;
                        }
                    }
                }

                margin-top: 10px;
                //----------News & Announcements---------//
                .news_announcments{
                    .flexbox{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .flex{
                            width: 49%;
                            height: max(300px,35vh);
                            margin: 15px 0px;
                            background-color: white;
                            overflow: hidden;
                            border: 1px solid $brand_blue; 
                            box-shadow: 0px 0px 30px rgb(238, 238, 238);
                            border-radius: 5px;
                            transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1);
                            .content{
                                display: flex;
                                flex-direction: column;
                                height: 100%;
                                .img{
                                    width: 100%;
                                    min-height: 25vh;
                                   background-color: $banner_color;
                                   background-size: cover;
                                   overflow: hidden;
                                   position: relative;

                                   .overlay{
                                    width: inherit;
                                    min-height: inherit;
                                    position: absolute;
                                    top: 0;
                                    z-index: 1;
                                   }
                                   
                                   iframe{
                                    width: inherit;
                                    height: 30vh;
                                    position: absolute;
                                    pointer-events: none;
                                    touch-action: none;
                                    z-index: 0;
                                    overflow: hidden;

                                    
                                       
                                   }
                                }
                                .img2{
                                  background: url(../images/executive_member_list.jpg);
                                  background-size: cover;
                                 
                                }
                                h3{
                                    margin: 15px 0;
                                    line-height: 1.3;
                                    font-size: clamp(1rem,2vw,1.3rem);
                                    color: $brand_blue;
                                    text-transform: capitalize;
                                    font-weight: 500;
                                   
                                }

                                .heading{
                                    padding-top: 10px;
                                }
                                p{
                                    margin: 0;
                                    font-size: .8rem;
                                }
                                


                                h3,a,p{
                                    padding: 0px 30px;
                                }

                                

                                a{
                                    padding-bottom: 20px;
                                    color: white;
                                }
                            }

                            &:hover{
                                transform: scale(1.02);
                            }
                        }
                    }
                }
                //----------Highlights---------//
                .highlights{
                    display: flex;
                    line-height: 1.8;
                    padding: 50px 0px;
                    .flex:nth-child(1){
                        width: 70%;
                        display: flex;
                        align-items: center;
                        .content{
                            width: max(80%);
                            h1{
                                margin: 0;
                                font-weight: 500;
                                color: $brand_green;
                                line-height: 1.5;
                                font-size:clamp(1rem,2vw,1.5rem);
                            }

                            p{
                                margin-top: 10px;
                                font-size: .9rem;
                                color: $paragraph-color;
                                line-height: 2;
                            }

                            button{
                                background-color: $brand_blue;
                                color: white;
                                border: none;
                                font-weight: bold;
                                padding: 20px 35px;
                                margin-top: 25px;
                                font-size: .8rem;
                                cursor: pointer;
                                border-radius: 5px;

                                &:hover{
                                    background-color: #026789;
                                }
                            }
                        }

                        
                    }

                    .flex:nth-child(2){
                        box-shadow: 0px 0px 30px rgb(224, 224, 224);
                        padding:25px 0px;
                        border-radius:8px;

                        .content{
                            ul{
                                font-size: .9rem;
                                line-height: 1.7;

                                li{
                                    margin-top: 10px;
                                    color: $paragraph-color;
                                    
                                    cursor: pointer;

                                    a{
                                        color: $brand_blue;
                                    }
                                }

                                li:nth-child(1){
                                    font-weight: 500;
                                }

                                li:nth-child(1),li:nth-child(5){
                                    list-style-type: none;
                                    color:$brand_green
                                }

                               li:nth-child(2),li:nth-child(3),li:nth-child(4){
                                    text-decoration: underline;
                                    font-size: .9rem;

                                    &:hover{
                                        color: $brand_blue;
                                    }
                                }

                                li:nth-child(2),li:nth-child(3),li:nth-child(4){
                                    background: url('../images/bullet_image.png') no-repeat left center;
                                    padding: 5px 30px 5px 65px;
                                    list-style: none;
                                    margin: 0;
                                    vertical-align: middle;
                                }

                                li:nth-child(2),li:nth-child(5){
                                    margin-top: 15px;
                                }
                            }
                        }
                    }
                }

                
            }

            //--Quick Access: Union Resources--//
            .quick_access{
                margin: 50px 0;
                h4{
                    color: $brand_green;
                }

                .flexbox{
                    display: flex;
                    justify-content: space-between;

                    a{
                        border: 1px solid $brand_blue;
                        border-radius: 10px;
                        width: 24%;
                        
                        display: flex;
                        align-items: center;
                        text-align: center;
                        cursor: pointer;
                        overflow: hidden;
                        
                        .flex{
                            display: flex;
                            width: 100%;

                            span{
                                width: 30%;
                                display: grid;
                                place-items: center;
                                color: white;
                                background-color: $brand_blue;
                            }

                            p{
                                flex-grow: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding:0 10px;
                            }

                            
                        }
                        

                        p{
                            margin: 0;
                            margin: 35px 0;
                            line-height: 1.5;
                            color: $brand_blue;
                            font-weight: 500;
                            font-size: .8rem;
                            height: 30px;
                        }
                        .img{
                            
                            img{
                                width: 80%;
                                display: none;
                            }
                        }

                        &:hover{
                            background-color: $blue_hover;
                            color: white;

                            p{
                                color: white;
                            }
                        }
                    }

                    a:nth-child(4){
                        background-color: $brand_blue;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        p{
                            color: white;
                            margin: 0; 
                        }

                        &:hover{
                            background-color: $blue_hover;
                        }
                        
                    }
                }
            }

            //----------Contact Us-----------//
            .contact{
               
                margin:70px 0;
                border-top: 1px solid #B9D332;
                .flexbox{
                    display: flex;
                    margin: auto;
                    margin-top: 60px;

                    
                    .flex{
                        display: flex;
                        align-items: center;

                        .content{
                            width: max(100%);

                            h3{
                                color: $brand_green;
                            }

                            h1{
                                font-size: $highlights_header;
                                color: $brand_blue;
                                margin: 30px 0px;
                                margin-top: 0;
                            }

                            .about_heading{
                                margin: 20px 0;
                                font-size: clamp(2.5px,6vw,2.4rem);
                            }

                            p{
                                line-height: 2;
                                color: $paragraph-color;
                                font-size: 1rem;
                                margin-top: 0;
                                
                            }

                            button{
                                margin-top:10px;
                                color: $brand_blue;
                                border: solid 1.5px $brand_blue;
                                background-color: white;
                                padding:15px 30px;
                                cursor: pointer;
                                font-weight: 700;
                                border-radius: 5px;

                                &:hover{
                                    background-color: $brand_blue;
                                    color: white;
                                }
                            }
                        }

                        .img{
                            img{
                                width: 100%;
                            }
                        }
                    }

                    .flex:nth-child(1){
                        width: 100%;
                    }

                    .flex:nth-child(2){
                        width: 40%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .img{
                            width: 80%;
                        }
                    }
                }

                

                .about_ceiu{
                    margin-top: 0px;
                    p{
                        line-height: 2.3;
                        font-weight: 400;
                        font-size: 1.1rem;
                        color: rgb(82, 82, 82);
                    }
                }

                p{
                    line-height: 1.7;
                }
            }

             //----------Resource Content-----------//
            .members_resources{
                .flexbox{
                    display: flex;
                    justify-content: space-between;
                    .flex{
                        aside{
                            background-color: white;
                            width: 100%;
                            height: fit-content;
                            margin-top: 100px;
                            ul{
                                padding-left: 0;
                                padding-bottom: 60px;

                                a{
                                    text-decoration: none;
                                    color: $brand_blue;
                                }
                                
                                li{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 20px 25px;
                                    text-transform: capitalize;
                                    border-bottom: solid 1px rgb(221, 221, 221);
                                    font-weight: 500;
                                    cursor: pointer;
                                    transition: .2s;
                                    
                                    &:hover{
                                        background-color: $brand_blue;
                                        color: white;
                                    }

                                    .resource_dropdown{
                                        display: none !important;
                                    }
                                }

                                li:nth-child(1){
                                    padding-bottom: 40px;
                                    font-weight: bold;
                                }

                                li:nth-child(2){
                                    background-color: $brand_blue;
                                    color: white;
                                }
                            }
                        }
                    }

                    .flex:nth-child(1){
                        display: flex;
                        width: 30%;
                    }
                    .flex:nth-child(2){
                        width: 65%;
                        
                        
                            .content{
                                margin-top: 50px;
                                h3,h1,p{
                                    margin: 0;
                                    line-height: 1.5;
                                }

                                h3{
                                    color: $brand_blue;
                                }

                                h1{
                                    color: $brand_green;
                                }

                                .heading{
                                    color: black;
                                    margin-top: 30px;
                                    text-transform: capitalize;
                                }

                                .documents{
                                    display: flex;
                                    
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    margin: 20px 0px;
                                    .flex{
                                        width: 48%;
                                        margin: 20px 0px;

                                        .doc_content{
                                            display: flex;
                                            height: 140px;
                                            flex-direction: row-reverse;
                                            justify-content: space-between;
                                            border: 1px solid $brand_blue;
                                            
                                            //align-items: center;

                                            .flex1{
                                                width: 70%;
                                                padding: 20px;

                                                p{
                                                    a{
                                                        font-size: .8rem;
                                                        color: $brand_green;
                                                    }
                                                }
                                            }
                                            .documents_icon{
                                                width: 20%;
                                                background-color: $brand_blue;
                                                color: white;
                                                cursor: pointer;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            }
                                            
                                        }
                                    }

                                    .flex:nth-child(1){
                                        background-color: white;
                                    }
                                }
                            }
                        
                    }
                }

                
                
            } 

            //----------Executive Member list-----------//
            .executive_list{
                .heading{
                    border-bottom: solid 1px $brand_blue;
                    padding-top: 50px;
                    padding-bottom: 30px;
                    margin-bottom: 30px;

                    h1{
                        color: $brand_blue;
                        font-weight: 500;
                    }

                    h6{
                        margin: 0;
                    }

                    p{
                        font-size: .9rem;
                    }
                  
                }

                .list_container{
                    .executives{
                        display: flex;
                        padding: 25px 0;
                        border-bottom: dotted 1px $brand_green;
                       

                        .exec_name{
                            width: 25%;
                            align-items: center;
                            font-weight: 500;
                            font-size: 1.1rem;
                            
                            p{
                                margin: 5px 0;
                                font-size: .9rem;
                                color: $brand_green;
                                font-weight: 300;
                                display: none;
                            }
                            img{
                                margin:0 20px;
                            }
                        }

                        .exec_position{
                            width: 25%;
                            flex-grow: 1;
                            font-weight: 400;
                            color: rgb(120, 120, 120);
                        }

                        .exec_email{
                            width: 25%;
                            text-decoration: underline;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            img{
                                width: 20px;
                                margin-right: 15px;
                                opacity: .4;
                            }
                        }
                        &:nth-child(even){
                            background-color: rgba(245, 245, 245, 0.481);
                        }

                    }

                    
                }
            }
             
        }

        //-----------Footer------------//
        footer{
            padding: 50px 0px;
            color: $brand_blue;
            background-color: $footer_color;

            .container{
                width: 80%;
                margin: auto;
                .flexbox{
                    display: flex;
                    justify-content: space-between;
    
                    .flex{
                        width: 33%;
    
                        h3{
                            font-size: 1rem;
                        }
    
                        p{
                            font-size: .8rem;
                            line-height: 1.8;
                        }
    
                        ul{
                            padding-left: 0;
    
                            li{
                                font-size: .8rem;
                                list-style-type: none;
                                line-height: 1.9;
    
                                a{
                                    color: $brand_blue;
                                }
                            }
                        }
                    }
    
                    .flex:nth-child(2),.flex:nth-child(3){
                        display: flex;
                        justify-content: flex-end;
                    }
                }

            }
            
         }

         //-----------Copyrights------------//
         .copyright{
            background-color: #dadada;
            .container{
                display: flex;
                justify-content: flex-end;
                p{
                    font-size: .7rem;
                    color: rgb(56, 56, 56);
                }
            }
         }
    }

    .section11{
        .text3{
            line-height: 1.5;
        }
    }

    //---------------------Admin Styles-----------///
    
    .material-symbols-outlined {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
    }

    .admin{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .admin_container{
          
            width: 30%;
            height: 50%;;

            .admin_logo{
                text-align: center;            
                   img{
                    width: 30%;
                }
             h4{
             margin-top: 55px;
             color: $brand_blue;
            font-size: 1.2rem;
            margin-bottom: 55px;    
        }
            }

            form{
                
                label{
                    
                    color: rgb(85, 83, 83);
                }

                .email_field{
                    width: 100%;
                    outline: none;
                    border: none;
                    border-bottom: solid .5px rgb(232, 230, 230);
                    font-size: 1rem;

                    &:focus{
                        border-color: $brand_blue;
                    }
                }

                .password_field{
                width: 100%;
                outline: none;
                border: none;
                border-bottom: solid .5px rgb(232, 230, 230);
                padding-bottom: 10px;
                font-size: 1rem;

                &:focus{
                    border-color: $brand_blue;
                }
                
                }

                .flexbox{
                    margin-top: 35px;
                   .password_container{
                    position: relative;

                    span{
                        position: absolute;
                        top: 0;
                        right: 5%;
                        transform: translateX(-5%);
                        cursor: pointer;
                    }
                   }
                }
            
                .sign_in{
                    border: none;
                    background-color: $brand_blue;
                    color: white;
                    padding: 15px 30px;
                    font-size: 1rem;
                    margin-top: 30px;
                    cursor: pointer;
                }

                
            
            
            }


            
        }

        .copyright{
            position: absolute;
            left: 50%;
            bottom: 5%;
            transform: translate(-50%,-5%);
            font-size: .8rem;
            color: gray;
        }
    }

    

    .dash_body{
        width: 100%;
       
//----------------- Dashboard Navigation ----------------------// 

       
        .dash_container{
            border-bottom: 1px solid $brand_blue;
            .dash_nav{
                width: 95%;
                margin: auto;
    
                .flexbox{
                    display: flex;
                    justify-content: space-between;
                    padding: 20px 0;
                    
                    .dash_img{
                        width: 15%;
                        display: flex;
                        align-items: center;
    
                        img{
                            width: 130px;
                        }
                    }
    
                    form{
                        width: 60%;
                        display: flex;
                        align-items: center;
                        
                        input{
                            width: 100%;
                            padding:17px 35px;
                            background-color: rgba(242, 242, 242, 0.497);
                            border: $blue_hover 2px solid;
                            border-radius: 5px;
                        }

                        .search_container{
                            width: 55%;
                            height: 29vh;
                            position: absolute;
                            top: 14%;
                            left: 45%;
                            transform: translate(-45%,-14%);
                            background-color: white;
                            z-index: 100;
                            box-shadow: 0px 5px 10px lightgray;
                            border: $brand_blue solid 2px;
                            border-radius: 5px;
                            display: none;
                           
                            overflow-y: scroll;

                            p{
                                border-bottom:1px solid lightgray ;
                                padding: 25px;
                                margin: 0;

                                &:nth-child(even){
                                    background-color: rgba(235, 235, 235, 0.503);
                                }

                                &:hover{
                                    background-color: $brand_blue;
                                    color: white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
    
                    .dash_actions{
                        width: 20%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
    
                        .actions_flexbox{
                            display: flex;
    
                            p{
                                font-size: 1.03rem;
                                margin-right: 20px;
                            }

                          
    
                            p:nth-child(1),.sign_out{
                                border: 2px solid $brand_blue;
                                padding: 15px 20px;
                                color: $brand_blue;
                                cursor: pointer;
                                font-weight: bold;
                                border-radius: 5px;
    
                                &:hover{
                                    color: white;
                                    font-weight: bold;
                                    background-color: $brand_blue;

                                }
                            }
                            
    
                            p:nth-child(2){
                                font-weight: bold;
                                display: flex;
                                align-items: center;
                            }
    
                            span{
                                font-size: 3rem;
                                display: flex;
                                align-items: center;
                                color: $brand_blue;
                            }
                        }
                    }
                }
            }
    
        }

        .dash_main{
            
            height:90%;
           

            .flexbox{
                display: flex;
                height: -webkit-fill-available;

//-----------------Grievance Applicants----------------------//
                .side_container{
                    border: .5px solid $brand_blue;
                    border-top: none;
                    border-bottom: none;
                    width: 25%;
                    height: inherit;
                    background-color: rgba(245, 244, 244, 0.396);

                    .griev_app_container{
                        width: 90%;
                        margin: auto;

                        p:nth-child(1){
                            color: $brand_blue;
                            font-weight: bold;
                            font-size: $dash-fonts;
                        }

                        .applicants_container{
                            margin-top: 30px;
                            height: 85vh;
                            overflow-y: scroll;
                            position: relative;
                            text-align: center;
                            
                            .parent_group{
                                display: flex;
                                flex-direction: column-reverse;
                            }

                            .date,.old_date{
                                text-align: left;
                                font-size: .8rem;
                                font-style: italic;
                            }
                            
                            .no_application_icon{
                                position: absolute;
                                top: 40%;
                                left: 50%;
                                transform: translate(-50%,-40%);
                                color: rgb(183, 181, 181);

                                .applications_icon{
                                    font-size: clamp(1.5rem,4vw,4rem);
                                }
                            }

                            .applicant{
                                margin: 10px 5px;
                                //display: flex;
                                //justify-content: space-between;
                                align-items: center;
                                //background-color: rgb(251, 251, 251);
                                background-color: white;
                                border: 1px rgb(163, 161, 161) solid;
                                border-radius: 5px;
                                position: relative;
                                transition: .1s;
                                cursor: pointer;
                                text-align: left;
                                &:hover{
                                    transform: scale(1.01);
                                    box-shadow: 0px 1px 3px rgb(194, 194, 194);
                                }
                            }

                            .app_name{
                                font-weight: bold;
                                padding: 16px 15px;
                            }

                            button{
                                border: none;
                                background-color: rgba(0, 0, 0, 0);
                                //padding: 10px 20px;
                                color: rgb(246, 73, 73);
                                border-radius: 5px;
                                cursor: pointer;
                                position: absolute;
                                right: 2%;
                                top: 50%;
                                transform: translate(-2%,-50%);
                            }
                        }
                    }
                }

//-----------------Grievance Application Details----------------//

                .flex:nth-child(2){
                    width: 80%;
                    p:nth-child(1){
                        color: $brand_blue;
                        font-weight: 500;
                        font-size: $dash-fonts;
                        width: 95%;
                        margin: 20px auto;
                    }
                    .grieve_details_container{
                        width: 100%;
                        min-height: 65%;

                       
                        .griev_app_details{
                            width: 95%;
                            margin: auto;
    
                           
    
                            .personal_info{
                                border: .5px solid lightgray;
                                border-radius: 5px;
                                padding: 15px 25px;
    
                                .container{
                                    h4{
                                        border-bottom: .5px solid lightgray;
                                        margin: 0;
                                        padding-bottom: 15px;
                                        font-size: $dash-fonts;
                                    }
    
                                    .info{
                                        font-size: $dash-fonts;
                                        height: 50px;
                                        .flexbox{
                                            padding: 20px 0px;
                                            border-bottom: solid .5px lightgray;
                                            justify-content: space-between;
                                            .flex{
                                                width: fit-content;
                                                border: none;
                                                
                                               
                                            }
    
                                            .flex:nth-child(odd){
                                                color: $brand_blue;
                                                font-size: .9rem;
                                            }
    
                                            .flex:nth-child(even){
                                                font-weight: bold;
                                            }
                                        }
                                    }
                                }
                            }
    
                            .griev_details{
                                height: 20vh;
                                margin-top: 10px;
                                
                                .container{
                                    div{
                                        padding: 20px 15px;
                                        line-height: 2;
                                        height: 14vh;
                                        overflow-y: scroll;
                                        color: rgb(88, 88, 88);
                                    }
                                }
                                
                            }
                        }
    
                        
                    }
                    .actions{
                        //border-top: .5px solid $brand_blue;
                        
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        min-height: 7vh;
                        width: 95%;
                        margin: 15px auto;

                        button{
                            background-color: $brand_blue;
                            width: 200px;
                            padding: 15px 5px;
                            border: none;
                            color: white;
                            font-weight: bold;
                            border-radius: 5px;
                            font-size: 1.1rem;
                            cursor: pointer;
                        }
                    }
                    

                    
                }
            }
        }

    }


}

